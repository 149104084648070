import PropTypes from "prop-types"
import React from "react"
import { Heading } from "../../01_atoms/Heading/Heading"
import { Paragraph } from "../../01_atoms/Paragraph/Paragraph"
import * as styles from "./timelineitem.module.scss"

const TimeLineItem = ({ title, description, image }) => {
  const imageSrcSet = image?.relationships.field_media_image.image_style_uri
  const url = image?.relationships.field_media_image.url

  return (
    <div className={styles.item}>
      <picture>
        <source type="image/webp" srcSet={imageSrcSet.original_webp} />
        <source type="image/png" srcSet={imageSrcSet.original_png} />
        <img src={url} alt={image.field_media_image.alt} />
      </picture>
      <div className={styles.inner}>
        <Heading size="m" align="center">
          {title}
        </Heading>
        <Paragraph align="center">
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Paragraph>
      </div>
    </div>
  )
}

TimeLineItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
}

TimeLineItem.defaultProps = {
  title: "",
  description: "",
  image: "./images/products/dummy.png",
}

export default TimeLineItem
