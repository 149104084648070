import cx from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { colors } from "../../../config/colors"
import { Heading } from "../../01_atoms/Heading/Heading"
import * as styles from "./BlogCard.module.scss"

const BlogCard = ({ title, link, image, color, external, className }) => {
  const imageSrcSet = image?.relationships.field_media_image.image_style_uri
  const url = image?.relationships.field_media_image.url

  if (link && external) {
    return (
      <a
        href={external.uri}
        className={cx(className, styles.card)}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.visual}>
          <div>
            className={styles.bg}
            style={{ background: `${colors[color]} url(${url})` }}
          </div>
        </div>
        <div className={styles.content}>
          <Heading size="s" element="h3">
            {title}
          </Heading>
          <a
            href={external.uri}
            className={styles.button}
            target="_blank"
            rel="noreferrer"
          ></a>
        </div>
      </a>
    )
  } else if (link) {
    return (
      <Link to={link} className={cx(className, styles.card)}>
        <div className={styles.visual}>
          <div
            className={styles.bg}
            style={{ background: `${colors[color]} url(${url})` }}
          ></div>
        </div>
        <div className={styles.content}>
          <Heading size="s" element="h3">
            {title}
          </Heading>
          <div to={link} className={styles.button}></div>
        </div>
      </Link>
    )
  } else {
    return (
      <div className={cx(className, styles.card, styles.statiCard)}>
        <div className={styles.visual}>
          <div
            className={styles.bg}
            style={{ background: `${colors[color]} url(${url})` }}
          ></div>
        </div>
      </div>
    )
  }
}

BlogCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object,
  color: PropTypes.string,
}

BlogCard.defaultProps = {
  title: "",
  description: "",
  link: "",
  image: "./images/products/dummy.png",
  color: "Dusky Rose",
}

export default BlogCard
