import { graphql } from "gatsby"
import React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import BlogCard from "../components/02_molecules/BlogCard/BlogCard"
import Section from "../components/03_organisms/Section/Section"

import * as blogStyles from "../components/02_molecules/BlogCard/BlogCard.module.scss"
import Carousel from "../components/03_organisms/Carousel/Carousel"
import TimeLineItem from "../components/03_organisms/TimelineItem/TimelineItem"
import { RenderComponent } from "../components/components"
import { useTranslation } from "react-i18next"

const BlogDetail = ({ data }) => {
  const pageData = data.allNodeBlog.nodes[0]
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />

      {pageData && (
        <>
          <ImageTextBlock
            height="100"
            className="hero"
            image_position="left"
            background_color={pageData.relationships.field_main_color.name}
            slanted
            image={pageData.relationships.field_image}
          >
            <Heading size="xxl" spacing="m">
              {pageData.title}
            </Heading>

            {pageData.body && (
              <Paragraph>
                <span
                  dangerouslySetInnerHTML={{
                    __html: pageData.body.processed,
                  }}
                />
              </Paragraph>
            )}
          </ImageTextBlock>

          {pageData.relationships.field_components?.map((component, index) =>
            component.internal?.type === "paragraph__carousel" ? (
              <Carousel key={index} title={component.field_title}>
                {component.relationships.field_items.map((item, index) => (
                  <TimeLineItem
                    title={item.field_title}
                    description={item.field_short_description}
                    image={item.relationships.field_image}
                  />
                ))}
              </Carousel>
            ) : (
              RenderComponent(component, index)
            )
          )}

          {data.recent.nodes.length > 0 && (
            <Section content>
              <Heading size="xl" spacing="l" align="center">
                {t("blog_recent_title")}
              </Heading>
              <div className={blogStyles.cards}>
                {data.recent.nodes.map(item => (
                  <BlogCard
                    title={item.title}
                    image={item.relationships.field_image}
                    link={item.path.alias}
                    color={item.relationships.field_main_color.name}
                  />
                ))}
              </div>
            </Section>
          )}
        </>
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default BlogDetail

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNodeBlog(
      filter: {
        langcode: { eq: "en" }
        status: { eq: true }
        path: { alias: { eq: $slug } }
      }
    ) {
      nodes {
        created(formatString: "DD/MM/YYYY")
        title
        field_image {
          drupal_internal__target_id
        }
        path {
          alias
        }
        field_meta_description
        field_meta_title

        body {
          processed
        }

        relationships {
          field_image {
            ...imageFragment
          }
          field_main_color {
            name
          }
          field_components {
            ... on Node {
              ...imageTextFragment
              ...standout
              ...carouselFragment
              ...videoFragment
            }
          }
        }
      }
    }

    recent: allNodeBlog(
      filter: {
        langcode: { eq: "en" }
        status: { eq: true }
        path: { alias: { ne: $slug } }
      }
      sort: { fields: created, order: DESC }
      limit: 3
    ) {
      nodes {
        created(formatString: "DD/MM/YYYY")
        title
        field_image {
          drupal_internal__target_id
        }
        path {
          alias
        }

        relationships {
          field_image {
            ...imageFragment
          }
          field_main_color {
            name
          }
        }
      }
    }
  }
`
